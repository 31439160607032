import { DatePicker, FormField, Select, TimeInput, Toggle } from '@amzn/awsui-components-react';
import React from 'react';
import { Interlude } from '../../data-types';
import { PropsWithDataStage } from '../StageContext';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { getSelection, time_zone_options } from '../../configs/multi-select-config';
import { DeepPartial } from '../../utils/typeUtils';

const ALL_DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export interface ScheduleTabProps extends PropsWithDataStage {
    current: Interlude;
    initial?: Interlude;
    onUpdate: (interlude: Interlude) => void;
}

type TimezoneId = 'absolute' | 'relative';

// Using state to construct data that is represented in separate React controls
interface ScheduleTabState {
    schedule: {
        start: {
            date?: string;
            time?: string;
        };
        end: {
            date?: string;
            time?: string;
        };
    };
    daysOfWeek: string[];
    hoursOfDay: {
        start?: string;
        end?: string;
    };
    timezoneId: TimezoneId;
    fieldErrors: Record<string, string>;
}

class ScheduleTab extends React.Component<ScheduleTabProps, ScheduleTabState> {
    constructor(props: ScheduleTabProps) {
        super(props);
        this.state = {
            schedule: {
                start: {},
                end: {},
            },
            daysOfWeek: [],
            hoursOfDay: {},
            fieldErrors: {},
            timezoneId: 'relative',
        };
    }

    componentDidMount() {
        this.updateState();
    }

    componentDidUpdate(prevProps: ScheduleTabProps): void {
        if (!_.isEqual(this.props.current.schedule, prevProps.current.schedule)) {
            this.updateState();
        }
    }

    getDateTimeFromString(dateTimeString?: string) {
        return dateTimeString ? DateTime.fromISO(dateTimeString).toUTC() : undefined;
    }

    updateState(callback?: () => void) {
        const startDateTime = this.getDateTimeFromString(this.props.current.schedule?.start?.dateTime);
        const endDateTime = this.getDateTimeFromString(this.props.current.schedule?.end?.dateTime);
        const startTime = this.getDateTimeFromString(this.props.current.schedule?.hoursOfDay?.start);
        const endTime = this.getDateTimeFromString(this.props.current.schedule?.hoursOfDay?.end);
        const schedule = {
            start: {
                date: startDateTime?.toFormat('yyyy-MM-dd') ?? this.state?.schedule.start.date,
                time: startDateTime?.toFormat('HH:mm') ?? this.state?.schedule.start.time,
            },
            end: {
                date: endDateTime?.toFormat('yyyy-MM-dd') ?? this.state?.schedule.end.date,
                time: endDateTime?.toFormat('HH:mm') ?? this.state?.schedule.end.time,
            },
        };
        const hoursOfDay = {
            start: startTime?.toFormat('HH:mm') ?? this.state?.hoursOfDay.start,
            end: endTime?.toFormat('HH:mm') ?? this.state?.hoursOfDay.end,
        };
        const missingText = 'Must provide a value';
        const fieldErrors = {
            scheduleStartDate: this.props.current.schedule?.start?.enabled && !schedule.start.date ? missingText : '',
            scheduleStartTime: this.props.current.schedule?.start?.enabled && !schedule.start.time ? missingText : '',
            scheduleEndDate: this.props.current.schedule?.end?.enabled && !schedule.end.date ? missingText : '',
            scheduleEndTime: this.props.current.schedule?.end?.enabled && !schedule.end.time ? missingText : '',
            availableHoursStart:
                this.props.current.schedule?.hoursOfDay?.enabled && !this.props.current.schedule?.hoursOfDay.start
                    ? missingText
                    : '',
            availableHoursEnd:
                this.props.current.schedule?.hoursOfDay?.enabled && !this.props.current.schedule?.hoursOfDay.end
                    ? missingText
                    : '',
        };
        const daysOfWeek = this.props.current.schedule?.daysOfWeek?.split(',') ?? ALL_DAYS;
        const timezoneId = !this.props.current.schedule?.useLocal ? 'absolute' : 'relative';
        this.setState({ schedule, daysOfWeek, hoursOfDay, fieldErrors, timezoneId }, callback);
    }

    updateInterlude() {
        const format = 'yyyy-MM-dd HH:mm';
        const timeOpts = { zone: 'utc' };
        const startDateTime =
            this.state.schedule.start.date && this.state.schedule.start.time
                ? DateTime.fromFormat(
                      `${this.state.schedule.start.date} ${this.state.schedule.start.time}`,
                      format,
                      timeOpts,
                  )
                : undefined;
        const endDateTime =
            this.state.schedule.end.date && this.state.schedule.end.time
                ? DateTime.fromFormat(
                      `${this.state.schedule.end.date} ${this.state.schedule.end.time}`,
                      format,
                      timeOpts,
                  )
                : undefined;
        const startTime = this.state.hoursOfDay.start
            ? DateTime.fromISO(this.state.hoursOfDay.start, timeOpts)
            : undefined;
        const endTime = this.state.hoursOfDay.end ? DateTime.fromISO(this.state.hoursOfDay.end, timeOpts) : undefined;
        const schedule: DeepPartial<Interlude['schedule']> = {
            start: {
                dateTime: startDateTime?.toUTC().toISO(),
            },
            end: {
                dateTime: endDateTime?.toUTC().toISO(),
            },
            daysOfWeek: this.state.daysOfWeek.join(','),
            hoursOfDay: {
                start: startTime?.toUTC().toISOTime({ suppressSeconds: true }),
                end: endTime?.toUTC().toISOTime({ suppressSeconds: true }),
            },
            useLocal: this.state.timezoneId === 'relative',
        };
        const interlude = _.chain(this.props.current).cloneDeep().merge({ schedule }).value();
        this.props.onUpdate(interlude);
    }

    startEnabledChange = (event: CustomEvent<Toggle.ChangeDetail>) => {
        const interlude = _.chain(this.props.current)
            .cloneDeep()
            .merge({ schedule: { start: { enabled: event.detail.checked } } })
            .value();
        this.props.onUpdate(interlude);
    };

    startDateChange = (event: CustomEvent<DatePicker.ChangeDetail>) => {
        const schedule = _.chain(this.state.schedule)
            .cloneDeep()
            .merge({ start: { date: event.detail.value } })
            .value();
        this.setState({ schedule }, () => this.updateInterlude());
    };

    startTimeChange = (event: CustomEvent<TimeInput.ChangeDetail>) => {
        const schedule = _.chain(this.state.schedule)
            .cloneDeep()
            .merge({ start: { time: event.detail.value } })
            .value();
        this.setState({ schedule }, () => this.updateInterlude());
    };

    endEnabledChange = (event: CustomEvent<Toggle.ChangeDetail>) => {
        const interlude = _.chain(this.props.current)
            .cloneDeep()
            .merge({ schedule: { end: { enabled: event.detail.checked } } })
            .value();
        this.props.onUpdate(interlude);
    };

    endDateChange = (event: CustomEvent<DatePicker.ChangeDetail>) => {
        const schedule = _.chain(this.state.schedule)
            .cloneDeep()
            .merge({ end: { date: event.detail.value } })
            .value();
        this.setState({ schedule }, () => this.updateInterlude());
    };

    endTimeChange = (event: CustomEvent<TimeInput.ChangeDetail>) => {
        const schedule = _.chain(this.state.schedule)
            .cloneDeep()
            .merge({ end: { time: event.detail.value } })
            .value();
        this.setState({ schedule }, () => this.updateInterlude());
    };

    dayOfWeekChange = (day: string, enabled: boolean) => {
        let daysOfWeek: string[];
        if (enabled) {
            daysOfWeek = this.state.daysOfWeek.concat(day);
        } else {
            daysOfWeek = _.without(this.state.daysOfWeek, day);
        }
        this.setState({ daysOfWeek }, () => this.updateInterlude());
    };

    hoursOfDayEnabledChange = (event: CustomEvent<Toggle.ChangeDetail>) => {
        const interlude = _.chain(this.props.current)
            .cloneDeep()
            .merge({ schedule: { hoursOfDay: { enabled: event.detail.checked } } })
            .value();
        this.props.onUpdate(interlude);
    };

    hoursOfDayStartChange = (event: CustomEvent<TimeInput.ChangeDetail>) => {
        const hoursOfDay = _.chain(this.state.hoursOfDay).cloneDeep().merge({ start: event.detail.value }).value();
        this.setState({ hoursOfDay }, () => this.updateInterlude());
    };

    hoursOfDayEndChange = (event: CustomEvent<TimeInput.ChangeDetail>) => {
        const hoursOfDay = _.chain(this.state.hoursOfDay).cloneDeep().merge({ end: event.detail.value }).value();
        this.setState({ hoursOfDay }, () => this.updateInterlude());
    };

    timezoneChange = (event: CustomEvent<Select.ChangeDetail>) => {
        const timezoneId = event.detail.selectedOption.id as TimezoneId;
        this.setState({ timezoneId }, () => this.updateInterlude());
    };

    render(): JSX.Element {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        // const responsiveField = 'col-xxxs-12 col-xxs-8';
        const responsiveFieldHalf = 'col-xxxs-6 col-xxs-4';
        const fixedLabel = 'col-xxxs-5 col-xxs-4';
        const fixedField = 'col-xxxs-7 col-xxs-8';
        const timeFieldHint = 'In 24H format';
        const timezoneHint = this.state.timezoneId === 'absolute' ? 'Dates and times are shown in UTC' : '';
        return (
            <div className="awsui-grid">
                <div className="awsui-row">
                    <div className="col-xxxs-12 col-xs-6">
                        <div className="awsui-util-container">
                            <div className="awsui-util-container-header">Start schedule</div>
                            <div className="awsui-grid">
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Enabled:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.props.current.schedule?.start?.enabled}
                                        onChange={this.startEnabledChange}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={responsiveLabel}>Start Date/Time:</h4>
                                    <FormField
                                        className={responsiveFieldHalf}
                                        stretch={true}
                                        errorText={this.state.fieldErrors['scheduleStartDate']}
                                    >
                                        <DatePicker
                                            placeholder="YYYY/MM/DD"
                                            todayLabel="Today"
                                            nextMonthLabel="Next month"
                                            value={this.state?.schedule.start.date ?? ''}
                                            onChange={this.startDateChange}
                                            previousMonthLabel="Previous month"
                                            disabled={!this.props.current.schedule?.start?.enabled}
                                        />
                                    </FormField>
                                    <FormField
                                        className={responsiveFieldHalf}
                                        stretch={true}
                                        hintText={timeFieldHint}
                                        errorText={this.state.fieldErrors['scheduleStartTime']}
                                    >
                                        <TimeInput
                                            format="hh:mm"
                                            placeholder="hh:mm"
                                            value={this.state?.schedule.start.time ?? ''}
                                            onChange={this.startTimeChange}
                                            disabled={!this.props.current.schedule?.start?.enabled}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                        <div className="awsui-util-container">
                            <div className="awsui-util-container-header">Available days</div>
                            <div className="awsui-grid">
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Sunday:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.state?.daysOfWeek.includes('SUN')}
                                        onChange={(ev) => this.dayOfWeekChange('SUN', ev.detail.checked)}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Monday:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.state?.daysOfWeek.includes('MON')}
                                        onChange={(ev) => this.dayOfWeekChange('MON', ev.detail.checked)}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Tuesday:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.state?.daysOfWeek.includes('TUE')}
                                        onChange={(ev) => this.dayOfWeekChange('TUE', ev.detail.checked)}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Wednesday:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.state?.daysOfWeek.includes('WED')}
                                        onChange={(ev) => this.dayOfWeekChange('WED', ev.detail.checked)}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Thursday:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.state?.daysOfWeek.includes('THU')}
                                        onChange={(ev) => this.dayOfWeekChange('THU', ev.detail.checked)}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Friday:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.state?.daysOfWeek.includes('FRI')}
                                        onChange={(ev) => this.dayOfWeekChange('FRI', ev.detail.checked)}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Saturday:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.state?.daysOfWeek.includes('SAT')}
                                        onChange={(ev) => this.dayOfWeekChange('SAT', ev.detail.checked)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxxs-12 col-xs-6">
                        <div className="awsui-util-container">
                            <div className="awsui-util-container-header">End schedule</div>
                            <div className="awsui-grid">
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Enabled:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.props.current.schedule?.end?.enabled}
                                        onChange={this.endEnabledChange}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={responsiveLabel}>End Date/Time:</h4>
                                    <FormField
                                        className={responsiveFieldHalf}
                                        stretch={true}
                                        errorText={this.state.fieldErrors['scheduleEndDate']}
                                    >
                                        <DatePicker
                                            placeholder="YYYY/MM/DD"
                                            todayLabel="Today"
                                            nextMonthLabel="Next month"
                                            value={this.state?.schedule.end.date ?? ''}
                                            onChange={this.endDateChange}
                                            previousMonthLabel="Previous month"
                                            disabled={!this.props.current.schedule?.end?.enabled}
                                        />
                                    </FormField>
                                    <FormField
                                        className={responsiveFieldHalf}
                                        stretch={true}
                                        hintText={timeFieldHint}
                                        errorText={this.state.fieldErrors['scheduleEndTime']}
                                    >
                                        <TimeInput
                                            format="hh:mm"
                                            placeholder="hh:mm"
                                            value={this.state?.schedule.end.time ?? ''}
                                            onChange={this.endTimeChange}
                                            disabled={!this.props.current.schedule?.end?.enabled}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                        <div className="awsui-util-container">
                            <div className="awsui-util-container-header">Available hours</div>
                            <div className="awsui-grid">
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Enabled:</h4>
                                    <Toggle
                                        className={fixedField}
                                        checked={!!this.props.current.schedule?.hoursOfDay?.enabled}
                                        onChange={this.hoursOfDayEnabledChange}
                                    />
                                </div>
                                <div className="awsui-row">
                                    <h4 className={responsiveLabel}>Start/End Time:</h4>
                                    <FormField
                                        className={responsiveFieldHalf}
                                        stretch={true}
                                        hintText={timeFieldHint}
                                        errorText={this.state.fieldErrors['availableHoursStart']}
                                    >
                                        <TimeInput
                                            format="hh:mm"
                                            placeholder="hh:mm"
                                            value={this.state.hoursOfDay?.start ?? ''}
                                            onChange={this.hoursOfDayStartChange}
                                            disabled={!this.props.current.schedule?.hoursOfDay?.enabled}
                                        />
                                    </FormField>
                                    <FormField
                                        className={responsiveFieldHalf}
                                        stretch={true}
                                        hintText={timeFieldHint}
                                        errorText={this.state.fieldErrors['availableHoursEnd']}
                                    >
                                        <TimeInput
                                            format="hh:mm"
                                            placeholder="hh:mm"
                                            value={this.state.hoursOfDay?.end ?? ''}
                                            onChange={this.hoursOfDayEndChange}
                                            disabled={!this.props.current.schedule?.hoursOfDay?.enabled}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                        <div className="awsui-util-container">
                            <div className="awsui-util-container-header">Options</div>
                            <div className="awsui-grid">
                                <div className="awsui-row">
                                    <h4 className={fixedLabel}>Timezone:</h4>
                                    <FormField className={fixedField} hintText={timezoneHint}>
                                        <Select
                                            options={time_zone_options}
                                            selectedOption={getSelection(time_zone_options, this.state.timezoneId)}
                                            onChange={this.timezoneChange}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScheduleTab;
