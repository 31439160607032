import React from 'react';
import { Button, Flashbar, Form, FormSection, Input, Select, TokenGroup } from '@amzn/awsui-components-react';
import { FlashbarItem, itemError, itemSuccess } from './commons/flash-messages';
import { fetchCreateReport } from '../utils/fetchUtil';
import { withDataStage } from './StageContext';
import { Report } from '../data-types';
import {
    report_type,
    service_tiers_for_reports,
    available_market_options,
    tracklist_source,
    getSelection,
} from '../configs/multi-select-config';
import { UploadButton } from '../components/assets/UploadButton';
import { uploadFile } from '../utils/reportsUtil';
import HelpInfoLink from './help/HelpInfoLink';
import { HelpPanel } from '../components/help/HelpPanel';

export interface State {
    report_type: string;
    tracklist_source: string;
    station_key?: string;
    territory: string;
    tier: string;
    flashbar: FlashbarItem[];
    creating: boolean;
    selected_file?: File;
}

class CreateReport extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            report_type: 'COVERAGE',
            tracklist_source: 'FILE',
            station_key: undefined,
            tier: 'UNLIMITED',
            territory: 'US',
            flashbar: [],
            creating: false,
            selected_file: undefined,
        };
    }

    handleTypeChange = (event: CustomEvent): void => {
        this.setState({ report_type: event.detail.selectedOption.id });
    };

    handleTierChange = (event: CustomEvent): void => {
        this.setState({ tier: event.detail.selectedOption.id });
    };

    handleTerritoryChange = (event: CustomEvent): void => {
        this.setState({ territory: event.detail.selectedOption.id });
    };

    handleTracklistSourceChange = (event: CustomEvent): void => {
        this.setState({ tracklist_source: event.detail.selectedOption.id });
    };

    handleStationKeyChange = (event: CustomEvent): void => {
        this.setState({ station_key: event.detail.value });
    };

    handleFileChange = (files: File[]) => {
        if (!files || files.length == 0) {
            return;
        }
        const file = files[0];
        this.setState({ selected_file: file });
    };

    handleRemoveFile = (_e: CustomEvent<TokenGroup.DismissDetail>): void => {
        this.setState({ selected_file: undefined });
    };

    createReport = async (): Promise<void> => {
        const flashbar = [];
        const report: Report = {
            report_type: this.state.report_type,
            tracklist_source: this.state.tracklist_source,
            file_uri: undefined,
            station_key: this.state.station_key,
            tier: this.state.tier,
            territory: this.state.territory,
        };
        this.setState({ creating: true });
        try {
            // Upload file if needed
            if (this.state.tracklist_source === 'FILE' && this.state.selected_file !== undefined) {
                report.file_uri = await uploadFile(this.state.selected_file as File);
            }
            // Create report
            const response = await fetchCreateReport(report);
            flashbar.push(
                itemSuccess(`Report created successfully`).withButton('Download report', () => {
                    const download_link: string = response.output_files.XLSX;
                    window.location.href = download_link;
                }),
            );
            this.setState({ flashbar: flashbar.concat(), creating: false });
        } catch (e) {
            flashbar.push(itemError('Failed to create report', e));
            this.setState({ flashbar: flashbar, creating: false });
            return;
        }
    };

    getFileItem = (file: File): TokenGroup.Item => {
        return {
            label: file.name,
            description: file.type,
            tags: [],
            iconName: 'file',
        };
    };

    renderFileUploadHelp = () => {
        return (
            <HelpPanel header="File Upload">
                <p>
                    Upload a CSV file with the list of tracks to create a report for. The file must contain a list of
                    track ASINs under a column titled <strong>playable asin</strong>.
                </p>
            </HelpPanel>
        );
    };

    renderForm(): JSX.Element {
        const isFileTracklist = this.state.tracklist_source === 'FILE';
        const isStationKeyTracklist = this.state.tracklist_source === 'STATION_KEY';
        const isFileChosen = this.state.selected_file !== undefined;
        const responsiveColumn = 'col-xxxs-12 col-xs-6';
        const responsiveLabel = 'col-xxxs-12 col-xxs-3';
        const responsiveField = 'col-xxxs-12 col-xxs-9';
        return (
            <div className="awsui-grid">
                <div className="awsui-row">
                    <FormSection className={responsiveColumn} header="Information">
                        <div className="awsui-row">
                            <h4 className={responsiveLabel}>Report Type:</h4>
                            <Select
                                className={responsiveField}
                                options={report_type}
                                selectedOption={getSelection(report_type, this.state.report_type)}
                                selectedLabel="Selected"
                                id="type"
                                onChange={this.handleTypeChange}
                            />
                        </div>
                        <div className="awsui-row">
                            <h4 className={responsiveLabel}>Tier:</h4>
                            <Select
                                className={responsiveField}
                                options={service_tiers_for_reports}
                                selectedOption={getSelection(service_tiers_for_reports, this.state.tier)}
                                selectedLabel="Selected"
                                id="type"
                                onChange={this.handleTierChange}
                            />
                        </div>
                        <div className="awsui-row">
                            <h4 className={responsiveLabel}>Territory:</h4>
                            <Select
                                className={responsiveField}
                                options={available_market_options}
                                selectedOption={getSelection(available_market_options, this.state.territory)}
                                selectedLabel="Selected"
                                id="territory"
                                onChange={this.handleTerritoryChange}
                            />
                        </div>
                        <div className="awsui-row">
                            <h4 className={responsiveLabel}>Tracklist Source:</h4>
                            <Select
                                className={responsiveField}
                                options={tracklist_source}
                                selectedOption={getSelection(tracklist_source, this.state.tracklist_source)}
                                selectedLabel="Selected"
                                id="tracklist_source"
                                onChange={this.handleTracklistSourceChange}
                            />
                        </div>
                        {isFileTracklist && (
                            <div className="awsui-row">
                                <h4 className={responsiveLabel}>
                                    File:
                                    <HelpInfoLink content={this.renderFileUploadHelp()} />
                                </h4>
                                {!isFileChosen ? (
                                    <UploadButton
                                        className={responsiveField}
                                        variant="link"
                                        icon="file-open"
                                        accept={'.csv'}
                                        multiple={false}
                                        onFileChange={this.handleFileChange}
                                    >
                                        Upload file
                                    </UploadButton>
                                ) : (
                                    <TokenGroup
                                        className={responsiveField}
                                        items={[this.getFileItem(this.state.selected_file as File)]}
                                        onDismiss={this.handleRemoveFile}
                                    />
                                )}
                            </div>
                        )}
                        <div className="awsui-row">
                            <h4 className={responsiveLabel}>Station Key:</h4>
                            <Input
                                className={responsiveField}
                                type="text"
                                id="station_key"
                                value={this.state.station_key}
                                onChange={this.handleStationKeyChange}
                                ariaRequired={isStationKeyTracklist}
                                placeholder={isStationKeyTracklist ? 'required' : 'optional'}
                            />
                        </div>
                    </FormSection>
                </div>
            </div>
        );
    }

    render(): JSX.Element {
        const createEnabled =
            (this.state.tracklist_source === 'FILE' && this.state.selected_file) ||
            (this.state.tracklist_source === 'STATION_KEY' && this.state.station_key);
        const formButtons = (
            <Button
                variant="primary"
                loading={this.state.creating}
                onClick={this.createReport}
                disabled={!createEnabled}
            >
                Create
            </Button>
        );
        return (
            <div>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Form header="Create report" actions={formButtons}>
                    {this.renderForm()}
                </Form>
            </div>
        );
    }
}

export default withDataStage(CreateReport);
