import React from 'react';
import { applyMode, Mode } from '@amzn/awsui-global-styles';
import { DataStage, StageContext } from './StageContext';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon } from '@amzn/awsui-components-react';

enum VisualMode {
    Light,
    Dark,
    Auto,
}

const VisualModeSetting = 'visualMode';

interface State {
    visualMode: VisualMode;
    noStages: boolean;
}



class Header extends React.Component<RouteComponentProps, State> {
    static contextType = StageContext;
    context!: StageContext;
    lightMql?: MediaQueryList;
    darkMql?: MediaQueryList;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            visualMode: VisualMode.Auto,
            noStages: false,
        };
    }

    componentDidMount() {
        this.initVisualModeListeners();
        this.loadVisualMode();
        this.onRouteChange();
    }

    componentWillUnmount() {
        this.cleanupVisualModeListeners();
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.onRouteChange();
        }
    }

    onRouteChange() {
        this.setState({
            noStages:
                this.props.location.pathname.startsWith('/queue') ||
                this.props.location.pathname.startsWith('/changes'),
        });
    }

    initVisualModeListeners() {
        // The browser will set calculate these media selectors based on the browser or OS setting
        this.lightMql = window.matchMedia('(prefers-color-scheme: light)');
        this.darkMql = window.matchMedia('(prefers-color-scheme: dark)');
        // These listeners will trigger when the preference changes
        this.lightMql.addEventListener('change', this.applyAutoScheme, { passive: true });
        this.darkMql.addEventListener('change', this.applyAutoScheme, { passive: true });
    }

    cleanupVisualModeListeners() {
        this.lightMql?.removeEventListener('change', this.applyAutoScheme);
        this.darkMql?.removeEventListener('change', this.applyAutoScheme);
    }

    applyAutoScheme = () => {
        if (this.state.visualMode == VisualMode.Auto) {
            if (this.lightMql?.matches) applyMode(Mode.Light);
            if (this.darkMql?.matches) applyMode(Mode.Dark);
        }
    };

    loadVisualMode() {
        let visualMode = VisualMode.Auto;
        switch (window.localStorage.getItem(VisualModeSetting)) {
            case VisualMode[VisualMode.Light]:
                visualMode = VisualMode.Light;
                break;
            case VisualMode[VisualMode.Dark]:
                visualMode = VisualMode.Dark;
                break;
        }
        this.setVisualMode(visualMode);
    }

    setVisualMode(visualMode: VisualMode) {
        window.localStorage.setItem(VisualModeSetting, VisualMode[visualMode]);
        this.setState({ visualMode: visualMode }, () => {
            switch (visualMode) {
                case VisualMode.Light:
                    applyMode(Mode.Light);
                    break;
                case VisualMode.Dark:
                    applyMode(Mode.Dark);
                    break;
                case VisualMode.Auto:
                    this.applyAutoScheme();
                    break;
            }
        });
    }

    render(): JSX.Element {
        return (
            <div id="h">
                <ul className="menu-list">
                    <li className="title">
                        <div>STORM Interlude Management</div>
                    </li>
                    <li className="ml-auto"></li>
                    <li>
                        <a href="https://amzn-media.slack.com/archives/G01D270TQQ4" target="_blank" rel="noreferrer">
                            #forge-tool <Icon name="external"></Icon>
                        </a>
                    </li>
                    {this.state.noStages || (
                        <>
                            <li
                                className={
                                    this.context.dataStage === DataStage.Sandbox ? 'selected separated' : 'separated'
                                }
                            >
                                <a onClick={() => this.context.setDataStage(DataStage.Sandbox)}>Sandbox</a>
                            </li>
                            <li className={this.context.dataStage === DataStage.Live ? 'selected' : ''}>
                                <a onClick={() => this.context.setDataStage(DataStage.Live)}>Live</a>
                            </li>
                        </>
                    )}
                    <li className={this.state.visualMode === VisualMode.Light ? 'selected separated' : 'separated'}>
                        <a onClick={() => this.setVisualMode(VisualMode.Light)}>Light</a>
                    </li>
                    <li className={this.state.visualMode === VisualMode.Dark ? 'selected' : ''}>
                        <a onClick={() => this.setVisualMode(VisualMode.Dark)}>Dark</a>
                    </li>
                    <li className={this.state.visualMode === VisualMode.Auto ? 'selected' : ''}>
                        <a onClick={() => this.setVisualMode(VisualMode.Auto)}>Auto</a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default withRouter(Header);
