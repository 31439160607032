import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AssetsTable from './components/AssetsTable';
import { BulkUploadInterludes, BulkUploadAssets } from './components/BulkUpload';
import ChangesTable from './components/ChangesTable';
import EditCreateAsset from './components/EditCreateAsset';
import EditInterlude from './components/EditInterlude';
import InterludesTable from './components/InterludesTable';
import QueueTable from './components/QueueTable';
import CreateReport from './components/CreateReport';

class AppContent extends React.Component {
    render(): React.ReactNode {
        return (
            <div>
                <Route exact={true} path="/">
                    <Redirect to="/interludes" />
                </Route>
                <Switch>
                    <Route path="/interludes/create-interlude" component={EditInterlude} />
                    <Route path="/interludes/upload-interludes" component={BulkUploadInterludes} />
                    <Route path="/interludes/:interludeId" component={EditInterlude} />
                    <Route path="/interludes" component={InterludesTable} />
                    <Route path="/assets/create-asset" component={EditCreateAsset} />
                    <Route path="/assets/upload-assets" component={BulkUploadAssets} />
                    <Route path="/assets/:assetId" component={EditCreateAsset} />
                    <Route path="/assets" component={AssetsTable} />
                    <Route path="/queue" component={QueueTable} />
                    <Route path="/changes" component={ChangesTable} />
                    <Route path="/reports/create-report" component={CreateReport} />
                </Switch>
            </div>
        );
    }
}

export default AppContent;
