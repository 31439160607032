import { auth } from '../auth/AuthGenerator';
import { getAPIGEndpoint } from '../configs/api-endpoints-config';
import {
    ArtistMetadata,
    Asset,
    Interlude,
    PresignedUrl,
    Report,
    ServerAsset,
    ServerInterlude,
    StagedResource,
} from '../data-types';
import { prepareObject, wrapValues, deepCopy } from './jsonUtil';
import { BulkUploadError } from './bulkUploadError'

function prepareInterlude(interlude: Interlude, upload: boolean): Interlude {
    prepareObject(interlude, {
        booleanFields: ['is_skippable', 'is_explicit', 'is_mixable', 'is_edtrl'],
        numberFields: ['rank'],
        removeFields: ['assets']
    })
    if (!upload) {
        // Store the assets originally returned by the server in a separate field
        // This field must be removed from the interlude object before sending it back to the server
        interlude.assets = {
            text: interlude.asset_ids?.text || [],
            media: interlude.asset_ids?.media || [],
            images: interlude.imgs || [],
        };
        // Fix the asset fields as they should be submitted on a subsequent POST using the existing fields
        interlude.all_asset_ids = interlude.assets.text.concat(interlude.assets.media).join(',');
        interlude.all_imgs = interlude.assets.images.map((img) => img.id).join(',');
    }
    return interlude;
}

function prepareAsset(asset: Asset, upload: boolean): Asset {
    prepareObject(asset, {
        booleanFields: ['auto_values'],
        numberFields: ['width_pixels', 'height_pixels'],
        removeFields: upload ? ['s3_bucket', 'presigned_url'] : []
    })
    if (asset.srcs) {
        asset.srcs.forEach(src => prepareObject(src, {numberFields: ['width_pixels', 'height_pixels']}))
    }
    if (!upload) {
        // The dimensions of media assets are included in the srcs array
        // Merging them with the rest of the top-level asset properties
        if (asset.srcs && asset.srcs.length > 0) {
            asset.width_pixels = asset.srcs[0].width_pixels;
            asset.height_pixels = asset.srcs[0].height_pixels;
        }
    }
    return asset;
}

function filterNextIndex(item: ServerInterlude|ServerAsset): boolean {
    return item.id !== '_next_index'
}


/**
 * Helper function to break an export request into smaller 'buckets'.
 * All the requested interlude/asset IDs are included in the API reqeust URL,
 *  thus a large number (>500) requested for export may exceed URL limits.
 */
function getExportBuckets(selection: string[]) {
    const maxLen = 500;
    const buckets = [];
    let start = 0;
    while (start < selection.length) {
        const bucket = selection.slice(start, start + maxLen).join(',');
        buckets.push(bucket);
        start += maxLen;
    }
    return buckets;
}

function combineData(dataArray: CsvExport[]) {
    let result = dataArray[0];
    for (let i = 1; i < dataArray.length; i++) {
        // Remove first entry of array containing headers, and concatenate to result array
        result = result.concat(dataArray[i].slice(1));
    }
    return result;
}

function getDataToDownload(dataPromises: Promise<CsvExport>[]) {
    return Promise.all(dataPromises)
        .then((dataArray) => {
            return combineData(dataArray);
        });
}

/**
 * FetchAPI wrapper to call the Forge backend APIG endpoint
 * Performs basic error handling of the response based on HTTP status code
 */
export function fetchBase(method: string, api: string, body?: BodyInit) {
    const url = getAPIGEndpoint() + api;
    const request = {
        method: method,
        headers: {
            Authorization: auth.getSignInUserSession().getIdToken().getJwtToken(),
        },
        body: body,
    };
    return fetch(url, request).then((response) => {
        if (!response.ok) {
            const reqid = response.headers.get('x-amzn-requestid');
            return response.text().then((text) => {
                console.error(`Server request failed: ${method}, ${api} => [${reqid}] ${text} (${response.status})`);
                let error = new Error(text || response.statusText);
                try {
                    if (JSON.parse(text) && JSON.parse(text).error_contexts_by_file_name) {
                        error = new BulkUploadError("One or more errors found while processing the CSV file", JSON.parse(text).error_contexts_by_file_name);
                    }
                } catch (e) {
                    console.log(`Error parsing JSON from response: ${e}`)
                }
                throw error;
            });
        }
        return response;
    });
}

/**
 * Extends fetchBase() to extract a JSON object from the Response
 */
export function fetchJson(method: string, api: string, body?: BodyInit) {
    return fetchBase(method, api, body).then((response) => response.json());
}

/**
 * Exports selected interludes to CSV
 */
export type DataRow = Array<string | number | boolean>;
export type CsvExport = Array<DataRow>;
export function fetchExportInterludes(dataStage: string, selectedInterludes: string[]) {
    const interludeIdBuckets = getExportBuckets(selectedInterludes);
    const interludePromises: Promise<CsvExport>[] = [];
    for (const interludeIds of interludeIdBuckets) {
        interludePromises.push(fetchJson('GET', `/api/interludes/csvexport?data_stage=${dataStage}&id=${interludeIds}`));
    }
    return getDataToDownload(interludePromises);
}

/**
 * Exports selected assets to CSV
 */
export function fetchExportAssets(dataStage: string, selectedAssets: string[]) {
    const assetIdBuckets = getExportBuckets(selectedAssets);
    const assetPromises: Promise<CsvExport>[] = [];
    for (const assetIds of assetIdBuckets) {
        assetPromises.push(fetchJson('GET', `/api/assets/csvexport?data_stage=${dataStage}&id=${assetIds}`))
    }
    return getDataToDownload(assetPromises);
}

/**
 * Retrieves all interludes from the server
 */
export function fetchGetAllInterludes(dataStage: string): Promise<ServerInterlude[]> {
    return fetchJson('GET', `/api/interludes/?data_stage=${dataStage}`)
        .then((json: ServerInterlude[]) => {
            return json.filter(filterNextIndex).map(i => prepareInterlude(i, false) as ServerInterlude)
        });
}

/**
 * Retrieves an interlude object from the server by ID
 */
export function fetchGetInterlude(interludeId: string, dataStage: string): Promise<ServerInterlude> {
    return fetchJson('GET', `/api/interludes/manage?id=${interludeId}&data_stage=${dataStage}`).then((json) => {
        if (!json.id) {
            throw new Error(`Could not find interlude in server`);
        }
        return prepareInterlude(json, false) as ServerInterlude;
    });
}

/**
 * Creates an interlude object
 */
export function fetchCreateInterlude(interlude: Interlude, dataStage: string) {
    const body = JSON.stringify(prepareInterlude(deepCopy(interlude), true));
    return fetchJson('PUT', `/api/interludes/manage?data_stage=${dataStage}`, body).then((json) => {
        if (!json.id) {
            throw new Error(`Server did not return new interlude id`);
        }
        return json.id;
    });
}

/**
 * Creates an interlude object
 */
export function fetchUpdateInterlude(interlude: Interlude, dataStage: string) {
    const copy = deepCopy(interlude);
    delete copy.id;
    const body = JSON.stringify(prepareInterlude(copy, true));
    return fetchJson('POST', `/api/interludes/manage?id=${interlude.id}&data_stage=${dataStage}`, body);
}

/**
 * Creates an interlude object
 */
export function fetchDeleteInterlude(interludeId: string, dataStage: string) {
    return fetchBase('DELETE', `/api/interludes/manage?id=${interludeId}&data_stage=${dataStage}`);
}

export function fetchRevertInterlude(interludeId: string, dataStage: string) {
    return fetchRevertChanges(dataStage, [{id: interludeId, type: "INTERLUDE"}])
}

/**
 * Retrieves all assets from the server
 */
 export function fetchGetAllAssets(dataStage: string): Promise<ServerAsset[]> {
    return fetchJson('GET', `/api/assets/?data_stage=${dataStage}`)
        .then((json: ServerAsset[]) => {
            return json.filter(filterNextIndex).map(a => prepareAsset(a, false) as ServerAsset)
        });
}

/**
 * Retrieves an asset object from the server by ID
 */
export function fetchGetAsset(assetId: string, dataStage: string): Promise<ServerAsset> {
    return fetchJson('GET', `/api/assets/manage?id=${assetId}&data_stage=${dataStage}`)
        .then((json: Asset) => {
            if (!json.id) {
                throw new Error('Could not find asset in server');
            }
            return prepareAsset(json, false) as ServerAsset;
        });
}

export function fetchCreateAsset(asset: Asset, dataStage: string) {
    const api = asset.type === 'TTS' ? '/api/assets/text' : '/api/assets/media';
    return fetchJson('POST', api + `?data_stage=${dataStage}`, JSON.stringify(deepCopy(prepareAsset(asset, true))))
        .then((json) => {
            if (!json.id) {
                throw new Error(`Server did not return new asset id`);
            }
            return json.id;
        });
}

export function fetchUpdateAsset(asset: Asset, dataStage: string) {
    const copy = deepCopy(asset);
    delete copy.id;
    const body = JSON.stringify(wrapValues(prepareAsset(copy, true)));
    return fetchJson('POST', `/api/assets/manage?id=${asset.id}&data_stage=${dataStage}`, body);
}

export function fetchDeleteAsset(assetId: string, dataStage: string) {
    return fetchBase('DELETE', `/api/assets/manage?id=${assetId}&data_stage=${dataStage}`);
}

export function fetchRevertAsset(assetId: string, dataStage: string) {
    return fetchRevertChanges(dataStage, [{ id: assetId, type: "ASSET" }])
}

export function fetchGetDefaultArtistImageAsset(asin: string, dataStage: string) {
    return fetchJson('GET', `/api/assets/media/artist?asin=${asin}&data_stage=${dataStage}`)
        .then((json: Asset) => {
            if (!json.id) {
                throw new Error('Could not find asset in server');
            }
            return prepareAsset(json, false) as ServerAsset;
        });
}

export function fetchGetPresignedS3Url(): Promise<PresignedUrl> {
    return fetchJson('GET', '/api/assets/creates3presignedurl');
}

export function fetchGetArtistMetadata(asin: string, mtr: string): Promise<ArtistMetadata> {
    return fetchJson('GET', `/api/metadata/artist?asin=${asin}&mtr=${mtr}`);
}

export function fetchUploadInterludes(data: FormData, dataStage: string) {
    return fetchJson('POST', `/api/interludes/bulkupload?data_stage=${dataStage}`, data);
}

export function fetchUploadAssets(data: FormData, dataStage: string) {
    return fetchJson('POST', `/api/assets/bulkupload?data_stage=${dataStage}`, data);
}

export function fetchGetQueueItems(queueId: string) {
    return fetchJson('GET', `/api/queue/?qid=${queueId}`);
}

/**
 * Get all changes staged in the given sandbox
 */
export function fetchGetAllChanges(dataStage: string) {
    return fetchJson('GET', `/api/changes/diff?data_stage=${dataStage}`);
}

/** 
 * Revert all or some of the changes staged in the given sandbox 
 */
export function fetchRevertChanges(dataStage: string, resources: StagedResource[]) {
    const body = {
        resources: resources
    }
    return fetchBase('POST', `/api/changes/revert?data_stage=${dataStage}`, JSON.stringify(body));
}

/**
 * Promote all or some of the changes staged in the given sandbox
 */
export function fetchPromoteChanges(dataStage: string, resources: StagedResource[]) {
    const body = {
        resources: resources
    }
    return fetchBase('POST', `/api/changes/promote?data_stage=${dataStage}`, JSON.stringify(body));
}

/**
 * Creates an interlude report
 */
export function fetchCreateReport(report: Report) {
    const api = '/api/reports/';
    return fetchJson('POST', api, JSON.stringify(deepCopy(prepareObject(report, {})))).then((json) => {
        return json;
    });
}

/**
 * Gets a presigned POST URL to upload to the reports input bucket
 */
export function fetchPresignedPostUrlForReports(fileName: string) {
    const request = {
        file_name: fileName,
    };
    return fetchJson('POST', `/api/reports/presignedposturl`, JSON.stringify(request));
}

/**
 * Gets a presigned GET URL to share objects in the reports input bucket
 */
export function fetchPresignedGetUrlForReports(fileName: string) {
    const request = {
        file_name: fileName,
    };
    return fetchJson('POST', `/api/reports/presignedgeturl`, JSON.stringify(request));
}
