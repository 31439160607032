import React from 'react';
import { Button, Flashbar, Form, FormSection, Modal, Spinner, Textarea } from '@amzn/awsui-components-react';
import Media from './assets/Media';
import Data from './assets/Data';
import { applyChange } from '../utils/eventUtil';
import { deepCopy } from '../utils/jsonUtil';
import {
    fetchCreateAsset,
    fetchDeleteAsset,
    fetchGetArtistMetadata,
    fetchGetAsset,
    fetchRevertAsset,
    fetchUpdateAsset,
} from '../utils/fetchUtil';
import { FlashbarItem, itemError, itemInfo, itemSuccess, itemWarning } from './commons/flash-messages';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader, { PageHeaderButton } from './PageHeader';
import HelpInfoLink from './help/HelpInfoLink';
import { replacementTokensHelp } from './help/HelpContent';
import { DataStage, PropsWithDataStage, withDataStage } from './StageContext';
import { Asset, AssetCategories, ServerAsset } from '../data-types';
import { findLargestImage, formatImage } from '../utils/imageUtil';
import { validatePreset } from '../utils/imageUtil';
import Jimp from 'jimp';
import ImageFormat from './assets/image/ImageFormat';
import { createPropertyStorage } from '../utils/jsonStorage';
import { DefaultArtistPreset, Preset } from '@amzn/forge-image-processing-types';
import MediaFooter from './assets/MediaFooter';
import { uploadMedia } from '../utils/assetUtil';

interface State {
    asset: Asset | ServerAsset;
    initial?: ServerAsset;
    flashbar: FlashbarItem[];
    loading: boolean;
    loadingArtist: boolean;
    loadingFile: boolean;
    saving: boolean;
    deleting: boolean; // Reused for 'reverting' as well
    selectedFile?: File;
    showSavePopup: boolean;
    showDeletePopup: boolean;
    showFormatPopup: boolean;
    showRevertPopup: boolean;
    hasImage: boolean;
    formatting: boolean;
    imageFormat: Preset;
    imageFormatErrors: { [index: string]: { [index: string]: string | undefined } };
}

interface RouteParams {
    assetId?: string;
}

interface Props extends RouteComponentProps<RouteParams>, PropsWithDataStage {
    isEdit?: boolean;
}

const INITIAL_STATE: State = {
    asset: {},
    flashbar: [],
    initial: undefined,
    selectedFile: undefined,
    loading: false,
    loadingArtist: false,
    loadingFile: false,
    saving: false,
    deleting: false,
    showSavePopup: false,
    showDeletePopup: false,
    showFormatPopup: false,
    showRevertPopup: false,
    hasImage: false,
    formatting: false,
    imageFormat: DefaultArtistPreset,
    imageFormatErrors: {},
};

const imageFormatStorage = createPropertyStorage<Preset>('Image-Format-Options');

class EditCreateAsset extends React.Component<Props, State> {
    asset: Asset = {};
    assetId?: string;
    isEdit = false;
    rawImage?: Buffer;
    filename?: string;
    dataRef = React.createRef<Data>();

    constructor(props: Props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(): void {
        this.loadComponent();
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.match?.params?.assetId !== this.props.match?.params?.assetId) {
            // User navigated to a different URL that maps to the same component
            // React does not (by design) unload the component, so we need to cleanup the state
            this.loadComponent();
        }
        if (prevProps.dataStage !== this.props.dataStage) {
            this.loadComponent();
        }
    }

    loadComponent(): void {
        if (!this.props.isEdit) {
            this.asset = {};
        }
        this.setState(INITIAL_STATE);
        // Load the latest valid image format options used by this user
        imageFormatStorage.load().then((imageFormat) => {
            if (imageFormat && validatePreset(imageFormat, true).length == 0) {
                this.setState({ imageFormat });
            }
        });
        this.assetId = this.props.match?.params?.assetId;
        this.isEdit = !!this.assetId;
        if (this.isEdit) {
            this.loadAssetDetails(this.assetId).catch((e) =>
                this.setState({ flashbar: [itemError('Failed to load asset', e)] }),
            );
        }
    }

    loadAssetDetails = (assetId?: string): Promise<void> => {
        if (!assetId) {
            return Promise.reject('Invalid asset ID specified');
        }
        this.setState({ loading: true });
        return fetchGetAsset(assetId, this.props.dataStage)
            .then((asset) => {
                this.asset = asset;
                this.setState({ asset: deepCopy(asset), initial: deepCopy(asset) });
            })
            .finally(() => this.setState({ loading: false }));
    };

    loadArtistImage = async () => {
        if (!this.state.asset.asin || !this.state.asset.mtr) {
            this.setState({
                flashbar: [itemError('Failed to retrieve image', 'Provide a valid value for ASIN and Marketplace')],
            });
            return;
        }
        this.setState({ loadingArtist: true });
        const asin = this.state.asset.asin;
        try {
            const metadata = await fetchGetArtistMetadata(asin, this.state.asset.mtr);
            const source = findLargestImage(metadata);
            if (!source) {
                throw Error('Artist metadata does not include images');
            }
            const image = await Jimp.read(source.url);
            const filename = `${asin}.${image.getExtension()}`;
            await this.handleImageLoaded(image, filename);
        } catch (error) {
            this.setState({ flashbar: [itemError('Failed to retrieve image', error)] });
        } finally {
            this.setState({ loadingArtist: false });
        }
    };

    handleChange = (e: CustomEvent): void => {
        const previous = deepCopy(this.asset);
        applyChange(e, this.asset);
        if (!this.isEdit && previous.type !== this.asset.type) {
            // Start from scratch to avoid carrying over fields that aren't valid for a given asset type
            this.asset = { type: this.asset.type };
        }
        if (this.asset.category === AssetCategories.DEFAULT_ARTIST_IMAGE) {
            // Artist images automatically load subject name as well
            this.asset.auto_values = true;
        }
        this.setState({ asset: deepCopy(this.asset) }, () => {
            if (this.asset.category == AssetCategories.DEFAULT_ARTIST_IMAGE) {
                if (
                    previous.category !== this.asset.category ||
                    previous.asin !== this.asset.asin ||
                    previous.mtr !== this.asset.mtr
                ) {
                    this.rawImage = undefined;
                    this.setState({ hasImage: false }, () => {
                        if (this.asset.asin?.length && this.asset.mtr?.length) {
                            this.loadArtistImage();
                        }
                    });
                }
            }
        });
    };

    handleFileChange = async (file: File[]) => {
        this.rawImage = undefined;
        this.setState({ hasImage: false, loadingFile: true });
        try {
            if (!file || file.length == 0) {
                this.setState({ selectedFile: undefined });
            } else {
                if (this.asset.type === 'IMAGE') {
                    const image = Buffer.from(await file[0].arrayBuffer());
                    await this.handleImageLoaded(image, file[0].name);
                } else {
                    this.asset.origfile = file[0].name;
                    this.setState({ selectedFile: file[0], asset: deepCopy(this.asset) });
                }
            }
        } finally {
            this.setState({ loadingFile: false });
        }
    };

    async handleImageLoaded(image: Jimp | Buffer, filename: string) {
        this.rawImage = Buffer.isBuffer(image) ? image : await image.getBufferAsync(image.getMIME());
        this.filename = filename;
        this.asset.origfile = filename;
        return this.applyFormatOptions();
    }

    handleAuthorUpdate = (author: string): void => {
        this.asset.author = author;
        this.setState({ asset: deepCopy(this.asset) });
    };

    handleFormatOptionsChange = (imageFormat: Preset) => {
        imageFormatStorage.save(imageFormat);
        this.setState({ imageFormat }, () => this.applyFormatOptions());
    };

    applyFormatOptions = async () => {
        if (!this.rawImage) return;
        this.setState({ formatting: true });
        try {
            const formatted = await formatImage(this.rawImage, this.state.imageFormat);
            const file = new File([await formatted.getBufferAsync(formatted.getMIME())], this.filename || '');
            this.asset.height_pixels = formatted.getHeight();
            this.asset.width_pixels = formatted.getWidth();
            this.setState({ hasImage: true, asset: deepCopy(this.asset), selectedFile: file });
        } catch (error) {
            this.setState({ flashbar: [itemError('Failed to format image', error)] });
        } finally {
            this.setState({ formatting: false });
        }
    };

    createAsset = async (): Promise<void> => {
        const flashbar = [];
        this.setState({ saving: true });
        try {
            if (this.asset.type !== 'TTS') {
                try {
                    const { filename, extension } = await uploadMedia(this.state.selectedFile);
                    this.asset.fileName = filename;
                    this.asset.extension = extension;
                    flashbar.push(itemSuccess('Media file uploaded successfully'));
                    this.setState({ flashbar: flashbar.concat() });
                } catch (e) {
                    this.setState({ flashbar: [itemError('Failed to upload media', e)] });
                    return;
                }
            }
            try {
                const id = await fetchCreateAsset(this.asset, this.props.dataStage);
                this.asset = { type: this.asset.type };
                flashbar.push(
                    itemSuccess(`Asset ${id} created successfully`).withButton('View asset', () => {
                        this.props.history.push(`/assets/${id}`);
                    }),
                );
                this.setState({ flashbar: flashbar.concat(), selectedFile: undefined, asset: deepCopy(this.asset) });
                this.dataRef.current?.onAssetSaved();
            } catch (e) {
                flashbar.push(itemError('Failed to update asset metadata', e));
                this.setState({ flashbar: flashbar });
                return;
            }
        } finally {
            this.setState({ saving: false });
        }
    };

    saveAsset = async (): Promise<void> => {
        const flashbar = [];
        this.setState({ saving: true, showSavePopup: false });
        try {
            if (this.state.selectedFile) {
                try {
                    const { filename, extension } = await uploadMedia(this.state.selectedFile);
                    this.asset.fileName = filename;
                    this.asset.extension = extension;
                    flashbar.push(itemSuccess('Media file uploaded successfully'));
                    this.setState({ flashbar: deepCopy(flashbar) });
                } catch (e) {
                    this.setState({ flashbar: [itemError('Failed to upload media', e)] });
                    return;
                }
            }
            try {
                await fetchUpdateAsset(this.asset, this.props.dataStage);
                flashbar.push(itemSuccess('Asset metadata updated successfully'));
                this.setState({ flashbar: deepCopy(flashbar), selectedFile: undefined });
                this.dataRef.current?.onAssetSaved();
            } catch (e) {
                flashbar.push(itemError('Failed to update asset metadata', e));
                this.setState({ flashbar: flashbar });
                return;
            }
            try {
                await this.loadAssetDetails(this.assetId);
            } catch (e) {
                flashbar.push(itemError('Failed to reload asset', e));
                this.setState({ flashbar: flashbar });
                return;
            }
        } finally {
            this.setState({ saving: false });
        }
    };

    revertAsset = (): Promise<void> => {
        if (!this.assetId) throw 'Cannot revert an interlude without ID';
        const assetId = this.assetId;
        this.setState({ deleting: true, showRevertPopup: false });
        return fetchRevertAsset(this.assetId, this.props.dataStage)
            .then(() => {
                // If fetching the asset fails then the change was likely only in the SANDBOX
                // In that case, navigate back to the assets table
                this.setState({ flashbar: [itemSuccess('Asset changes reverted')] });
                fetchGetAsset(assetId, this.props.dataStage)
                    .then(() => this.loadAssetDetails(this.assetId))
                    .catch(() => this.props.history.push('/assets', { reload: true }));
            })
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to revert asset', error)] });
            })
            .finally(() => this.setState({ deleting: false }));
    };

    deleteAsset = (): Promise<void> => {
        if (!this.assetId) throw 'Cannot revert an interlude without ID';
        const assetId = this.assetId;
        this.setState({ deleting: true, showDeletePopup: false });
        return fetchDeleteAsset(assetId, this.props.dataStage)
            .then(() => {
                this.props.history.push('/assets', { reload: true });
            })
            .catch((error) =>
                this.setState({
                    deleting: false,
                    flashbar: [itemError('Failed to delete asset', error)],
                }),
            );
    };

    renderForm(isEdit: boolean): JSX.Element {
        const hasMedia = this.state.asset.type === 'IMAGE' || this.state.asset.type === 'AUDIO';
        const hasRawImage = this.state.asset.type === 'IMAGE' && this.state.hasImage;
        const loadingMedia = this.state.loadingArtist || this.state.loadingFile || this.state.formatting;
        const hasTemplate = this.state.asset.type === 'TTS';
        const responsiveColumn = 'col-xxxs-12 col-xs-6';
        return (
            <div className="awsui-grid">
                <div className="awsui-row">
                    <FormSection className={responsiveColumn} header="Information">
                        <Data
                            ref={this.dataRef}
                            asset={this.state.asset}
                            isEdit={isEdit}
                            readonly={false}
                            onChange={this.handleChange}
                            onAuthorUpdate={this.handleAuthorUpdate}
                        />
                    </FormSection>
                    {hasMedia && (
                        <FormSection
                            className={responsiveColumn}
                            header="Media"
                            footer={
                                <MediaFooter
                                    asset={this.state.asset}
                                    canChangeFormat={hasRawImage}
                                    formatting={this.state.formatting}
                                    loadingFile={this.state.loadingFile}
                                    loadingArtist={this.state.loadingArtist}
                                    onChangeFormat={() => this.setState({ showFormatPopup: true })}
                                    onFileChange={this.handleFileChange}
                                    onLoadArtistImage={this.loadArtistImage}
                                />
                            }
                        >
                            {!loadingMedia && <Media asset={this.state.asset} selectedFile={this.state.selectedFile} />}
                        </FormSection>
                    )}
                    {hasTemplate && (
                        <FormSection
                            className={responsiveColumn}
                            header="Template"
                            footer={
                                <>
                                    You can use replacement tokens
                                    <HelpInfoLink content={replacementTokensHelp()} />
                                </>
                            }
                        >
                            <Textarea
                                value={this.state.asset.template}
                                id="template"
                                onChange={this.handleChange}
                                rows={7}
                            />
                        </FormSection>
                    )}
                </div>
            </div>
        );
    }

    render(): JSX.Element {
        const headerButtons: PageHeaderButton[] = [
            {
                text: 'Save',
                disabled: this.state.deleting,
                loading: this.state.saving,
                variant: 'primary',
                onClick: () => this.setState({ showSavePopup: true }),
            },
        ];
        if (this.props.dataStage === DataStage.Live) {
            headerButtons.unshift({
                text: 'Delete',
                disabled: this.state.saving,
                loading: this.state.deleting,
                onClick: () => this.setState({ showDeletePopup: true }),
            });
        }
        const formButtons = (
            <Button
                variant="primary"
                disabled={this.state.asset.type !== 'TTS' && !this.state.selectedFile}
                onClick={this.createAsset}
                loading={this.state.saving}
            >
                Create
            </Button>
        );
        let flashbar = this.state.flashbar;
        if (this.isEdit && this.props.dataStage == DataStage.Live) {
            const item = itemWarning(
                'You are editing Live content',
                'The changes to this Interlude are being made against the Live stage and will be visible to all customers. Please make sure this is intended before saving your changes.',
            ).isDismissible(false);
            flashbar = flashbar.concat(item);
        }
        if (this.state.asset?.data_stage === DataStage.Sandbox) {
            const item = itemInfo('', 'This asset has staged changes. Showing contents from the sandbox below.')
                .isDismissible(false)
                .withButton('Unstage', () => this.setState({ showRevertPopup: true }));
            flashbar = flashbar.concat(item);
        }
        return (
            <div>
                <Flashbar items={flashbar}></Flashbar>
                <Modal
                    visible={this.state.showDeletePopup}
                    header="Delete this Asset?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showDeletePopup: false })}>
                                {this.props.dataStage === DataStage.Live ? 'Cancel' : 'Ok'}
                            </Button>
                            {this.props.dataStage === DataStage.Live && (
                                <Button variant="primary" onClick={this.deleteAsset}>
                                    Confirm
                                </Button>
                            )}
                        </span>
                    }
                >
                    This will delete the Asset record from LIVE along with any staged changes in the SANDBOX.
                </Modal>
                <Modal
                    visible={this.state.showRevertPopup}
                    header="Revert changes?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showRevertPopup: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.revertAsset}>
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will remove the staged changes for this Asset.
                </Modal>
                <Modal
                    visible={this.state.showSavePopup}
                    header="Save Asset?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showSavePopup: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.saveAsset}>
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will update the Asset with the current specified information.
                </Modal>
                <ImageFormat
                    format={this.state.imageFormat}
                    visible={this.state.showFormatPopup}
                    onApply={this.handleFormatOptionsChange}
                    onHide={() => this.setState({ showFormatPopup: false })}
                />
                {this.isEdit &&
                    (this.state.loading ? (
                        <span className="awsui-util-status-inactive">
                            <Spinner /> Loading
                        </span>
                    ) : (
                        this.state.asset && (
                            <>
                                <PageHeader text={`Edit asset: ${this.state.asset.id}`} buttons={headerButtons} />
                                {this.renderForm(true)}
                            </>
                        )
                    ))}
                {!this.isEdit && (
                    <Form header="Create asset" actions={formButtons}>
                        {this.renderForm(false)}
                    </Form>
                )}
            </div>
        );
    }
}

export default withDataStage(EditCreateAsset);
